import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import "react-data-table-component-extensions/dist/index.css"

import DataTable from "react-data-table-component"
import DataTableExtensions from "react-data-table-component-extensions"

import axios from "axios"
 
import { DOMAIN_API, getUserLanguage, translate, URL_IS_AUTH,AUTH_TOKEN_KEY } from "../utils"
import { Button, Col, Image, Row } from "react-bootstrap"
import ButtonMenu from "../components/ButtonMenu"
import confirmation from "../components/modalConfirm"

export default function Adviceinfo() {
  const lang = getUserLanguage()

  let keytitle = `title${lang === "FR" ? "FR" : ""}`
  let keysubtitle = `subTitle${lang === "FR" ? "FR" : ""}`

  /********** sET tABLE CONFIG *********** */
  //  const [partner,setPartner]=useState();
  const [data, setData] = useState()
  const [role, setRole] = useState()
  const [column, setColumn] = useState({
    name: translate("VideoLink"),

    sortable: true,
    center: true,
    cell: c => (
      <a
        href={`${lang === "FR" ? c.videoMediaLinkFR : c.videoMediaLink}`}
        target="_blank"
      >
        {translate("Link Youtube")}
      </a>
    ),
  })
  const columns = [
    {
      name: "Image",
      selector: "id",
      sortable: true,
      center: true,
      cell: c => (
        <Image
          className="w-75"
          src={`${DOMAIN_API()}${c.s420x350Banner}`}
          rounded
        />
      ),
    },

    {
      name: translate(`Title`),
      selector: keytitle,
      sortable: true,
      center: true,
    },
    {
      name: translate("SubTitle"),
      selector: keysubtitle,
      sortable: true,
      center: true,
    },
    {
      name: translate("validity Date Start"),
      selector: "validityDateStart",
      sortable: true,
      center: true,
    },
    {
      name: translate("validity Date End"),
      selector: "validityDateEnd",
      sortable: true,
      center: true,
    },

    {
      name: translate("Creat_on"),
      selector: "dateOfCreation",
      sortable: true,
      center: true,
      //    cell:c=>{if(c.isVideoAdvice)setColumn({
      //     name: translate("PDF Link"),

      //     sortable: true,
      //     center: true,
      //     cell:c=><a href={`${lang==="FR" ? c.videoMediaLinkFR :c.videoMediaLink}`} target="_blank">{translate("Link Youtube")}</a>
      //    })}
    },
    column,
    {
      name: translate("Actions"),
      sortable: true,
      center: true,
      cell: c => (
        <>
          <Button className="mr-2" href={`/add-advice?id=${c.id}&typeOfAdvice=advice`}>
            <i className="fa fa-edit"></i>
          </Button>
          <Button variant="danger" id={`btn-${c.id}`}  onClick={openConfirme}>
      
            <i className="fa fa-trash" id={`i-${c.id}`} aria-hidden="true">    </i>
          </Button>
        </>
      ),
    },
  ]
  const table = {
    columns,
    data,
  }
  const openConfirme = (e) => {
    confirmation(
      translate("confirmer"),
      translate("voulez vous supprimer cette vidéo ?"),
      clickDelete(e)
    )
  
  }
  const clickDelete =(e)=>{
  let id=e.target.id.split('-')[1];
  let fr = new FormData();
  fr.append("id",id);
  fr.append("typeOf","advice");
    axios
      .post(`https://enter-business.com/new/delete_advice`,fr)
      .then(res => {
        if (res.data=="ok") {
         window.location.reload();
        }
      })
      .catch(err => {
        if (err.response) {
          console.log(err.response.data)
        }
        console.log(err)
      })
  }
  /**************** Send Request ********************/
  const getAdvice = async id => {
    await axios
      .get(`https://enter-business.com/new/advices?authorId=${id}`)
      .then(res => {
        if (res.data) {
          setData(res.data)
          console.log(res.data)
        }
      })
      .catch(err => {
        if (err.response) {
          console.log(err.response.data)
        }
        console.log(err)
      })
  }

  const auth = async () => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    const data = {
      headers: { Authorization: `Bearer ${token}` },
    }
    const url = URL_IS_AUTH
    await axios
      .get(url, data)
      .then(res => {
        if (res.data) {
          getAdvice(res.data.id)
          setRole(res.data.role)
          if(res.data.role==="user")
          {
            window.location.href = "/feed";
          }
        }
      })
      .catch(err => {
        if (err.response) {
          console.log(err.response.data)
        }
        console.log(err)
      })
  }

  /*************************************************/
  useEffect(() => {
    if (localStorage.getItem(AUTH_TOKEN_KEY)) auth()
  }, [])
  /************************************ */
  return (
    <div>
      <Layout>
        <div className="mt-2">{role != "user" ? <ButtonMenu /> : ""}</div>
        <Row className="d-flex justify-content-center">
          <h2>{translate("Advice Lsit")}</h2>
        </Row>
        <div className="card-body datatable-react">
          {role != "user" ? (
            <Row>
                          <Col lg="2" sm="4" className="mb-2 offset-lg-5  offset-md-5 offset-sm-5">
                <Button
                  href="/add-advice?typeOfAdvice=article"
                  className=" w-100 btn-secondary text-ceter"
                >
                  {translate("Add Article")}
                </Button>
              </Col>
            
            
            </Row>
          ) : (
            ""
          )}

          <DataTableExtensions {...table}>
            <DataTable
              pagination={true}
              striped={true}
              center={true}
              persistTableHead
            />
          </DataTableExtensions>
        </div>
      </Layout>
    </div>
  )
}
